import React from "react";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "../styles/blog.css";
import MetaData from "../components/common/MetaData";
import {metaData} from "../config"
const Casestudies = (props) => {
  const { nodes: posts } = props.data.allStrapiCasestudies;
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? "/casestudies"
      : "/casestudies" + `/${currentPage - 1}`;
  const nextPage = "/casestudies" + `/${currentPage + 1}`;

  return (
    <Layout>
      <MetaData pageTitle="Case Studies" description={metaData.casestudy.description} keyword={metaData.casestudy.keyword}/>
      <section className="section">
        <div className="container pb-5">
          <div className="content">
            <div className="row pt-5">
              <div className="col-lg-12 blogTitle text-center">
                <h3 className="pt-4">Groot Case Studies</h3>
                <p>
                  Initiatives we've delivered; We have a proven experience in
                  providing solutions to a broad range of industries. Here we
                  have mentioned a few case studies on how we deliver the power
                  of digital in measurable ways
                </p>
              </div>

              {posts &&
                posts.map((post, index) => {
                  const createdImage = getImage(post.image);
                  return (
                    <div className={`col-xl-4 col-lg-6 col-md-6 post-${index}`} key={index}>
                      <article className="blog-list-item row tile is-child post-featured">
                        <Link
                          to={"/casestudies/" + post.slug}
                          className="stretched-link"
                          title={`${post.title} page link`}
                          noPrefetch
                        ></Link>
                        <div className="col-lg-12 blogroll-image px-0">
                          {post.image ? (
                            <div className="featured-thumbnail">
                              <GatsbyImage
                                image={createdImage}
                                alt={`${post.casestudy_author.name} casestudies author image`}
                              />
                            </div>
                          ) : null}
                        </div>
                        <div className="col-lg-12 content-box">
                          <div className="post-author">
                            <strong>
                              {post.casestudy_author.name} on{" "}
                              {post.published_at}.
                            </strong>
                            <span> {post.readTime}</span>
                          </div>
                          <h3>{post.title}</h3>
                          <p>{post.description}</p>
                        </div>
                      </article>
                    </div>
                  );
                })}
              <div className="post-pagination">
                {!isFirst && (
                  <Link
                    className="tagmanager-casestudies-prev prev"
                    to={prevPage}
                    rel="prev"
                    title={`previous page link`}
                    noPrefetch
                  >
                    Previous Page
                  </Link>
                )}
                {!isLast && (
                  <Link
                    className="tagmanager-casestudies-next next"
                    to={nextPage}
                    rel="next"
                    title={`next page link`}
                    noPrefetch
                  >
                    Next Page
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query GetAllCasestudies($skip: Int!, $limit: Int!) {
    allStrapiCasestudies(
      sort: { order: DESC, fields: published_at }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        content
        description
        slug
        title
        published_at(formatString: "MMMM DD, YYYY")
        readTime
        image {
          childImageSharp {
            gatsbyImageData(quality: 10, width: 400, placeholder: NONE)
          }
        }
        casestudy_author {
          slug
          name
          role
        }
      }
    }
  }
`;

export default Casestudies;
